import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "event-types"
    }}>{`Event Types`}</h1>
    <p>{`Triggers when an event from `}<a parentName="p" {...{
        "href": "https://livesession.io/help/what-is-a-session/#:~:text=A%20session%20is%20a%20set,a%20website%20within%20a%20timeframe."
      }}>{`session`}</a>{` is handled.
Currently supported events are:`}</p>
    <ul>
      <li parentName="ul">{`JSError`}</li>
      <li parentName="ul">{`NetError`}</li>
      <li parentName="ul">{`ErrorClick`}</li>
      <li parentName="ul">{`RageClick`}</li>
      <li parentName="ul">{`Custom`}</li>
    </ul>
    <h2 {...{
      "id": "sessionevent"
    }}>{`session.event`}</h2>
    <h4 {...{
      "id": "jserror"
    }}>{`JSError`}</h4>
    <p>{`Triggers when a JS error is handled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message_id": "d5932de4-a8da-4546-4439-3c640ba8dc05",
  "webhook_id": "542de45",
  "webhook_type": "session.event",
  "api_version": "v1.0",
  "account_id": "3ca3b1b",
  "website_id": "8d53ea3",
  "created_at": 1691161881,
  "payload": {
    "visitor": {
      "id": "e4d5932d-4439-4546-a8da-a8d40bc053c6",
      "name": "John Doe",
      "email": "john.doe@livesession.io",
      "params": [
        {
          "name": "plan",
          "value": "pro"
        }
      ],
      "geolocation": {
        "country_code": "PL",
        "city": "Wroclaw",
        "region": "Dolnoslaskie"
      }
    },
    "event_name": "JSError",
    "time": 16911618810,
    "count": 1,
    "value": "ProductCatalog: product is not valid"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "neterror"
    }}>{`NetError`}</h4>
    <p>{`Triggers when a network request error is handled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message_id": "d5932de4-a8da-4546-4439-3c640ba8dc05",
  "webhook_id": "542de45",
  "webhook_type": "session.event",
  "api_version": "v1.0",
  "account_id": "3ca3b1b",
  "website_id": "8d53ea3",
  "created_at": 1691161881,
  "payload": {
    "visitor": {
      "id": "e4d5932d-4439-4546-a8da-a8d40bc053c6",
      "name": "John Doe",
      "email": "john.doe@livesession.io",
      "params": [
        {
          "name": "plan",
          "value": "pro"
        }
      ],
      "geolocation": {
        "country_code": "PL",
        "city": "Wroclaw",
        "region": "Dolnoslaskie"
      }
    },
    "event_name": "NetError",
    "time": 16911618810,
    "method": "GET",
    "url": "https://api.livesession.io/products/1",
    "status": 500
  }
}
`}</code></pre>
    <h4 {...{
      "id": "errorclick"
    }}>{`ErrorClick`}</h4>
    <p>{`Triggers when a `}<a parentName="p" {...{
        "href": "https://livesession.io/blog/error-rage-clicks/"
      }}>{`error click`}</a>{` is handled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message_id": "d5932de4-a8da-4546-4439-3c640ba8dc05",
  "webhook_id": "542de45",
  "webhook_type": "session.event",
  "api_version": "v1.0",
  "account_id": "3ca3b1b",
  "website_id": "8d53ea3",
  "created_at": 1691161881,
  "payload": {
    "visitor": {
      "id": "e4d5932d-4439-4546-a8da-a8d40bc053c6",
      "name": "John Doe",
      "email": "john.doe@livesession.io",
      "params": [
        {
          "name": "plan",
          "value": "pro"
        }
      ],
      "geolocation": {
        "country_code": "PL",
        "city": "Wroclaw",
        "region": "Dolnoslaskie"
      }
    },
    "event_name": "ErrorClick",
    "time": 16911618810,
    "messsage": "product is not defined"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "rageclick"
    }}>{`RageClick`}</h4>
    <p>{`Triggers when a `}<a parentName="p" {...{
        "href": "https://livesession.io/blog/error-rage-clicks/"
      }}>{`rage click`}</a>{` is handled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message_id": "d5932de4-a8da-4546-4439-3c640ba8dc05",
  "webhook_id": "542de45",
  "webhook_type": "session.event",
  "api_version": "v1.0",
  "account_id": "3ca3b1b",
  "website_id": "8d53ea3",
  "created_at": 1691161881,
  "payload": {
    "visitor": {
      "id": "e4d5932d-4439-4546-a8da-a8d40bc053c6",
      "name": "John Doe",
      "email": "john.doe@livesession.io",
      "params": [
        {
          "name": "plan",
          "value": "pro"
        }
      ],
      "geolocation": {
        "country_code": "PL",
        "city": "Wroclaw",
        "region": "Dolnoslaskie"
      }
    },
    "event_name": "RageClick",
    "time": 16911618810,
    "clicks": 5
  }
}
`}</code></pre>
    <h4 {...{
      "id": "custom"
    }}>{`Custom`}</h4>
    <p>{`Triggers when a custom event is handled.
These events are made during recording through the `}<inlineCode parentName="p">{`__ls(“track”)`}</inlineCode>{` Browser API function.
The payload includes event specifics and potential validation errors for added properties.`}</p>
    <p>{`The properties field is a map with varying value types.
Value type depends on the property key.
For instance, "_str" suffixed keys hold string values and "_bool" suffixed keys contain boolean values. Additional details are available in the knowledge base.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "message_id": "d5932de4-a8da-4546-4439-3c640ba8dc05",
  "webhook_id": "542de45",
  "webhook_type": "session.event",
  "api_version": "v1.0",
  "account_id": "3ca3b1b",
  "website_id": "8d53ea3",
  "created_at": 1691161881,
  "payload": {
    "visitor": {
      "id": "e4d5932d-4439-4546-a8da-a8d40bc053c6",
      "name": "John Doe",
      "email": "john.doe@livesession.io",
      "params": [
        {
          "name": "plan",
          "value": "pro"
        }
      ],
      "geolocation": {
        "country_code": "PL",
        "city": "Wroclaw",
        "region": "Dolnoslaskie"
      }
    },
    "event_name": "Custom",
    "time": 16911618810,
    "name": "Product added",
    "properties": {
      "product_id_int": 1,
      "product_name_str": "Product 1"
    }
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      